<template>
  <v-container fluid>
    <v-row align="stretch">
      <v-col cols="12" md="8" class="pa-1">
        <v-card elevation="12" rounded="lg" class="flex-card">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <div class="pa-2 ml-3">
                <h1 class="error--text">Welcome Back!</h1>
                <h2 class="font-weight-bold">
                  สำนักอำนวยการ กลุ่มงานจัดการบุคคล 2
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mt-1 text-center">
                <v-img
                  class="animate__animated animate__bounceIn"
                  src="hrovec.png"
                  height="200px"
                  contain
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="pa-1">
        <v-card class="flex-card" elevation="12" rounded="lg">
          <div class="pa-10 text-center">            
            <v-img
                  class="animate__animated animate__bounceIn"
                  src="hrvec.png"
                  height="100px"
                  contain
                ></v-img>

            <v-btn to="/pages/login" rounded color="success" class="mt-2">
              <!-- <v-icon>mdi-login</v-icon> -->
              <lord-icon
                src="https://cdn.lordicon.com/kdduutaw.json"
                trigger="loop"
                stroke="bold"
                colors="primary:#FFFFFFFF,secondary:#ffffffff"
                style="width: 40px; height: 40px"
              >
              </lord-icon>
              LOG IN</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OcsMasterWelcome",
  data() {
    return {
      ApiKey: "mouovec2024",
      periods: []
    };
  },
  async mounted() {
    await this.periodQuery();
  },
  methods: {
    async periodQuery() {
      let result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey
      });
      this.periods = result.data;
    }
  }
};
</script>
